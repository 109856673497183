//greetings and text
export const SITE_GREETING = "Hey there! Thank you for visiting my personal site! The purpose of this website is to display a few of my personal projects, and showcase some of my technicals skills. New projects and features will be added over time. Please click around and play with a few of the services I have available here."
export const HIRE_ME_PLEASE = "My name is Alec and I am software engineer with 4+ years experience working fullstack with distributed systems. I have experience creating microservices and frontend components that have helped to service tens of thousands of customers. Currently, I am looking for work and would greatly appreciate your consideration. If you have any questions about my experience, or my future goals, please feel free to use any of the contact methods listed at the bottom of the page to get in touch with me! My resume is also available for viewing. Thanks again!"

//contact
export const LINKEDIN_URI = "https://www.linkedin.com/in/AlecLankford/";
export const EMAIL = "alec.douglas.lankford@gmail.com";
export const PHONE_NUMBER = "(774) 292-9951";
export const GIT_URI = "https://github.com/alecDLankford";
export const GIT_USER = "alecDLankford";
export const WEATHER_MAN_GIT = "weather-man"


//weather-man
export const WEATHER_MAN_GCP = "This microservice is hosted on Google Cloud Platform (GCP). Please note that there might be occasional delays in responses, as the service may experience a cold start if it hasn't been used recently."
export const WEATHER_MAN_GREETING = "This service brings you personalized weather updates with a local touch. Enter your city and state, and receive a weather report that captures the feel of your friendly local weather person, thanks to integration with the OpenWeatherMap API and GPT-4o-mini."
export const STATE_ABBREVIATIONS = {
    "Alabama": "AL",
    "Alaska": "AK",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "Florida": "FL",
    "Georgia": "GA",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Pennsylvania": "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY",
  };
  