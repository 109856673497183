
export const fetchReadme = async (owner, repo) => {
    try {
        const res = await fetch(
            `https://api.github.com/repos/${owner}/${repo}/readme`,
            {
                headers: {
                    Accept: 'application/vnd.github.v3.raw'
                }
            }
        );

        if(!res.ok) {
            throw new Error('Response not ok ' + res.statusText);
        }

        const data = await res.text();
        return data;
    } catch (error) {
        console.error("Error returning README:", error);
    }
};