import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { CircularProgress, Typography } from "@mui/material";


function Resume() {
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const onLoadError = (error) => {
    console.error("Failed to load resume.", error);
    setError("Failed to load resume.");
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        p: 2,
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer onLoadSuccess={onLoadSuccess} fileUrl="/pdf/Alec_Lankford_Resume.pdf" />;
        </Worker>
      )}
    </Box>
  );
}

export default Resume;
