import Header from './components/Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import WeatherPage from './pages/WeatherPage';
import HomePage from './pages/HomePage';
import ResumePage from './pages/ResumePage';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Header />
      <div style={{display:'flex', flexDirection:'column', minHeight:'100vh'}}>
      <main style={{flex: 1, paddingBottom:'60px'}}>
        <Routes>
          <Route path = "/" element={<HomePage />}/>
          <Route path = "/weather-man" element={<WeatherPage />}/>
          <Route path = "/resume" element={<ResumePage />}/>
        </Routes>
      </main>
      <Footer />
      </div>
    </Router>
  );
}

export default App;
