import { React, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { STATE_ABBREVIATIONS } from "../constants/text";
import { fetchData } from "../api/weatherManService";
import SendIcon from '@mui/icons-material/Send'
import DeleteIcon from '@mui/icons-material/Delete'


const inputHeight = "56px";

function WeatherForm({ onWeatherData }) {
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClear = () => {
    setCity("");
    setState(null);
    setData(null);
    setError(null);
    onWeatherData(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const res = await fetchData(city, state.label);
      onWeatherData(res);
    } catch (error) {
      setError("Failed to fetch weather report, please try again.");
    } finally {
      setLoading(false);
    }
  };

  const stateOptions = Object.keys(STATE_ABBREVIATIONS).map((state) => ({
    label: state,
    abbreviation: STATE_ABBREVIATIONS[state],
  }));

  const inputWidth = useMemo(() => {
    const longestLabel = stateOptions.reduce(
      (max, option) => Math.max(max, option.label.length),
      0
    );
    return `${longestLabel * 16}px`;
  }, [stateOptions]);

  return (
    <Box
      component="weather-form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
        "& .MuiButton-root" : { height: inputHeight },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        width: '100%',
        maxWidth: '600px',
        mt: 4,
        mx: 'auto'
      }}
      noValidate
      autoComplete="off"
    >
      <form onSubmit={handleSubmit}>
        <Box sx={{ 
          display: "flex",
          flexDirection: {xs: "column", sm: "row"},
           gap: 2 ,
           width: '100%',
           maxWidth: '600px',
           }}>
          <TextField
            required
            id="outlined-required"
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            sx={{ mb: 2, flex: 1, minWidth: '150px' }}
          />
          <Autocomplete
            options={stateOptions}
            id="state-auto-complete"
            value={state}
            onChange={(e, newValue) => setState(newValue)}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                id="outlined-required"
                label="State"
                sx={{ mb: 2, flex: 1, minWidth: '200px' }}
              />
            )}
          />

        <Box sx={{ display: "flex", flexDirection: {xs: "column", sm: "row"}, gap: 2 }}>
          <LoadingButton
            variant="contained"
            loading={loading}
            loadingPosition="start"
            type="submit"
            sx={{ height: inputHeight, width: '100%', }}
            endIcon={<SendIcon />}
          >
            {!loading && "Submit"}
          </LoadingButton>
          <Button 
          variant="contained" 
          color="error" 
          onClick={handleClear} 
          sx={{ height: inputHeight, width: '100%' }}
          endIcon={<DeleteIcon />}
          >
            Clear
          </Button>
        </Box>
        </Box>
      </form>

    </Box>
  );
}

export default WeatherForm;
