import React from "react";
import { Typography, Box } from "@mui/material";
import { ReactTyped } from "react-typed";

function WeatherReport({ data }) {
    return (
        <Box
          sx={{
            backgroundColor: "#e0f7fa",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            p: 2,
            mt: 2,
            textAlign: "left",
            maxHeight: "300px",
            overflowY: "auto",
            width: '100%',
            maxWidth: '500px',
            mx: 'auto'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: "monospace",
              fontWeight: 500,
              color: "#00796b",
            }}
          >
            Weather Report:
          </Typography>
          <Typography variant="body1" sx={{ fontFamily: "monospace" }}>
            <ReactTyped
            strings={[data.weather_report]}
            typeSpeed={30}
            loop={false}
            />
          </Typography>
        </Box>
      );
}

export default WeatherReport;