import React from 'react';
import { Box, Typography, Link, IconButton, Container } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { EMAIL, GIT_URI, LINKEDIN_URI, PHONE_NUMBER } from '../constants/text';

function Footer() {
    return (
        <Box
        component="footer"
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          bgcolor: "primary.main",
          color: "white",
          py: 2,
          height: '60px',
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Container
        sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            maxWidth: "lg"
          }}
        >
                    <Box>
          <Typography variant="body1">
            <IconButton sx={{ color: "white" }}>
              <PhoneIcon />
            </IconButton>
            {PHONE_NUMBER}
          </Typography>
          <Typography variant="body1">
            <IconButton sx={{ color: "white" }}>
              <EmailIcon />
            </IconButton>
            <Link href="mailto:alec.douglas.lankford@gmail.com" color="inherit" underline="none">
              {EMAIL}
            </Link>
          </Typography>
        </Box>
        <Box>
          <Link href={GIT_URI} color="inherit" target="_blank">
            <IconButton sx={{ color: "white" }}>
              <GitHubIcon />
            </IconButton>
          </Link>
          <Link href={LINKEDIN_URI} color="inherit" target="_blank">
            <IconButton sx={{ color: "white" }}>
              <LinkedInIcon />
            </IconButton>
          </Link>
        </Box>
        </Container>
      </Box>
    );
}

export default Footer;