import React from "react";
import { HIRE_ME_PLEASE, SITE_GREETING } from "../constants/text";
import { Box, Typography } from "@mui/material";

function HomePage() {
    return (
        <Box
        sx={{
          backgroundColor: "#f0f0f0",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          p: 3,
          mb: 4,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".1rem",
            color: "#2c3e50",
          }}
        >
          {SITE_GREETING}
        </Typography>
       
        <Typography
          variant="h4"
          sx={{
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".1rem",
            color: "#2c3e50",
            mt: 8
          }}
        >
          {HIRE_ME_PLEASE}
        </Typography>

      </Box>

    );
}

export default HomePage;