import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import { Container, Toolbar, Typography, Box } from "@mui/material";

const hoverStyle = {
  fontFamily: "monospace",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
};

function Header() {
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Alec Lankford
            </Typography>
          </Link>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              width: "100%",
              justifyContent: "center",
              position: "absolute",
              left: 0,
              right: 0,
            }}
          >
            <div
              style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
            >
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  margin: "0 16px",
                }}
              >
                <Typography variant="body1" sx={hoverStyle}>
                  Home
                </Typography>
              </Link>
              <Link
                to="/weather-man"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  margin: "0 16px",
                }}
              >
                <Typography variant="body1" sx={hoverStyle}>
                  Weather Man
                </Typography>
              </Link>
              <Link
                to="/resume"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  margin: "0 16px",
                }}
              >
                <Typography variant="body1" sx={hoverStyle}>
                  Resume
                </Typography>
              </Link>
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
