import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Box, Typography, Switch, FormControlLabel } from "@mui/material";
import { fetchReadme } from "../api/readmeService";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function ReadMe({ owner, repo }) {
  const [readmeContent, setReadmeContent] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const getReadme = async () => {
      const content = await fetchReadme(owner, repo);
      setReadmeContent(content);
    };

    if (owner && repo) {
      getReadme();
    }
  }, [owner, repo]);

    const handleToggle = (event) => {
    setIsExpanded(event.target.checked);
  };

  return (
    <Box sx={{ p: 3 }}>
      <FormControlLabel
        control={<Switch checked={isExpanded} onChange={handleToggle} />}
        label="View README"
        sx={{ mb: 2 }}
        labelPlacement="start"
      />
      {isExpanded && (
        <Box sx={{ mt: 2, maxWidth: "100%", overflow: "auto" }}>
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => (
                <Typography
                  variant="h4"
                  sx={{ mt: 3, mb: 1, fontWeight: "bold", textAlign: "left" }}
                  {...props}
                />
              ),
              h2: ({ node, ...props }) => (
                <Typography
                  variant="h5"
                  sx={{ mt: 3, mb: 1, fontWeight: "bold", textAlign: "left" }}
                  {...props}
                />
              ),
              h3: ({ node, ...props }) => (
                <Typography
                  variant="h6"
                  sx={{ mt: 3, mb: 1, fontWeight: "bold", textAlign: "left" }}
                  {...props}
                />
              ),
              p: ({ node, ...props }) => (
                <Typography
                  variant="body1"
                  sx={{ mb: 2, textAlign: "left" }}
                  {...props}
                />
              ),
              ul: ({ node, ...props }) => (
                <Box component="ul" sx={{ pl: 4, mb: 2, textAlign: "left" }}>
                  {props.children}
                </Box>
              ),
              ol: ({ node, ...props }) => (
                <Box component="ol" sx={{ pl: 4, mb: 2, textAlign: "left" }}>
                  {props.children}
                </Box>
              ),
              li: ({ node, ...props }) => (
                <Typography
                  component="li"
                  sx={{ mb: 1, textAlign: "left" }}
                  {...props}
                />
              ),
              code: ({ node, ...props }) => (
                <Typography
                  component="code"
                  sx={{
                    fontFamily: "monospace",
                    backgroundColor: "#f5f5f5",
                    padding: "2px 4px",
                    borderRadius: "4px",
                    whiteSpace: "pre-wrap",
                    textAlign: "left",
                  }}
                  {...props}
                />
              ),
              pre: ({ node, ...props }) => (
                <Box
                  sx={{
                    backgroundColor: "#f5f5f5",
                    padding: "1rem",
                    borderRadius: "4px",
                    overflowX: "auto",
                    fontFamily: "monospace",
                    textAlign: "left",
                  }}
                >
                  <Typography component="pre" {...props} />
                </Box>
              ),
            }}
          >
            {readmeContent}
          </ReactMarkdown>
        </Box>
      )}
    </Box>
  );
}

export default ReadMe;
