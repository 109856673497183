import React, { useState } from "react";
import WeatherForm from "../components/WeatherForm";
import WeatherReport from "../components/WeatherReport";
import {
  GIT_USER,
  WEATHER_MAN_GCP,
  WEATHER_MAN_GIT,
  WEATHER_MAN_GREETING,
} from "../constants/text";
import { Box, Typography, Link, IconButton, Container } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import ReadMe from "../components/ReadMe";

function WeatherPage() {
  const [weatherData, setWeatherData] = useState(null);

  const handleWeatherData = (data) => {
    setWeatherData(data);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "100vh",
        overflowY: "auto",
        overflowX: "auto",
        p: 2,
        mb: "64px",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            p: 3,
            mb: 4,
            textAlign: "center",
            width: "100%"
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "#2c3e50",
            }}
          >
            {WEATHER_MAN_GREETING}
            <Link
              href="https://github.com/alecDLankford/weather-man"
              color="inherit"
              target="_blank"
            >
              <IconButton sx={{ color: "#2c3e50" }}>
                <GitHubIcon />
              </IconButton>
            </Link>
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "#2c3e50",
              mt: "12px",
            }}
          >
            {WEATHER_MAN_GCP}
          </Typography>
          <ReadMe owner={GIT_USER} repo={WEATHER_MAN_GIT} />
        </Box>
      </Container>
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        maxWidth: "600px", 
        alignItems: "center",
        mx: 'auto'

        }}>
        <WeatherForm onWeatherData={handleWeatherData} />
        {weatherData && <WeatherReport data={weatherData} />}
      </Box>
    </Box>
  );
}

export default WeatherPage;
