
export const fetchData = async (city, state) => {
  try {
    const response = await fetch(
      `https://weather-man-p2hsqwqyfa-uc.a.run.app/weather/${city}/${state}/imperial`,
      {
        method: "GET",
      }
    );

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error gathering weather report:", error);
  }
};
